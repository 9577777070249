@import url('https://fonts.googleapis.com/css2?family=Stardos+Stencil:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');

.app{
    background: rgba(255,255,255,.6);
}

header{
    font-family: 'Stardos Stencil', cursive;
    font-weight: bold;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 3px;
    text-shadow: 4px 4px 1px #000; 
    /* font-size: 84px; */
    line-height: 0.5;
    text-align: center;
}


nav, main{
    font-family: 'Rowdies', cursive; 
}

main h2{
    -webkit-text-fill-color: white;
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 3px;    
}

.nav-link a:link{
    text-decoration: none;
    color: #fff;
}

